import { useState } from 'react';
import { Image, Modal } from 'react-bootstrap';
// import the react-swipeable package
import { useSwipeable } from 'react-swipeable';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';

const MyModal = ({ images, isOpen, closeModal }) => {
    // hold the index of the current image
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handlers = useSwipeable({
        onSwipedLeft: () => setCurrentImageIndex(currentImageIndex < images.length - 1 ? currentImageIndex + 1 : 0),
        onSwipedRight: () => setCurrentImageIndex(currentImageIndex > 0 ? currentImageIndex - 1 : images.length - 1),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true // this will let us swipe with mouse events
    });

    const goNext = () => setCurrentImageIndex(currentImageIndex < images.length - 1 ? currentImageIndex + 1 : 0);
    const goPrev = () => setCurrentImageIndex(currentImageIndex > 0 ? currentImageIndex - 1 : images.length - 1);

    return (
        <Modal show={isOpen} onHide={closeModal} dialogClassName="modal-90w">
            <Modal.Body style={{position: 'relative', padding: 0}}>

                <button
                    onClick={goPrev}
                    style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        height: '100%',
                        width: '20%',
                        border: 'none',
                        background: 'transparent',
                        cursor: 'pointer',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.background = 'rgba(0, 0, 0, 0.3)';
                        e.target.firstChild.style.display = 'block';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.background = 'transparent';
                        e.target.firstChild.style.display = 'none';
                    }}
                >
                    <FaChevronLeft style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '2rem',
                        color: '#fff',
                        display: 'none'
                    }}/>
                </button>

                <button
                    onClick={goNext}
                    style={{
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        height: '100%',
                        width: '20%',
                        border: 'none',
                        background: 'transparent',
                        cursor: 'pointer',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.background = 'rgba(0, 0, 0, 0.3)';
                        e.target.firstChild.style.display = 'block';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.background = 'transparent';
                        e.target.firstChild.style.display = 'none';
                    }}
                >
                    <FaChevronRight style={{
                        position: 'absolute',
                        top: '50%',
                        right: '50%',
                        transform: 'translate(50%, -50%)',
                        fontSize: '2rem',
                        color: '#fff',
                        display: 'none'
                    }}/>
                </button>

                <button
                    onClick={closeModal}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        border: 'none',
                        background: '#fff',
                        color: '#000',
                        cursor: 'pointer',
                        fontSize: '1.5em',
                        borderRadius: '50%',
                        padding: '5px',
                        width: '1.5em',
                        height: '1.5em',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        lineHeight: '1.5em',
                    }}
                >
                    X
                </button>

                <Image src={images[currentImageIndex]} fluid/>

            </Modal.Body>
        </Modal>
    )
}

export default MyModal;