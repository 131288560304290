import React, { useState } from "react";
import { Image, Row, Col, Modal } from "react-bootstrap";
import IntroImage from "./images/intro.jpg";
import MeImage from "./images/me.jpg";
import Image1 from "./images/1.jpg";
import Image2 from "./images/2.jpg";
import Image3 from "./images/3.jpg";
import Image4 from "./images/4.jpg";
import Image5 from "./images/5.jpg";
import Image6 from "./images/6.jpg";
import Image7 from "./images/7.jpg";
import Image8 from "./images/8.jpg";
import Image9 from "./images/9.jpg";
import Image10 from "./images/10.jpg";
import Image11 from "./images/11.jpg";
import Image12 from "./images/12.jpg";
import Image13 from "./images/13.jpg";
import Image14 from "./images/14.jpg";
import MyModal from "./MyModal";

const Gallery = () => {
    const [imageToShow, setImageToShow] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const openModal = url => {
        setImageToShow(url);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const images = [
        Image1,
        Image2,
        Image3,
        Image4,
        Image5,
        Image6,
        Image7,
        Image8,
        Image9,
        Image10,
        Image11,
        Image12,
        Image13,
        Image14,
        IntroImage,
        MeImage
    ];

    const imgStyle = {
        maxWidth: '400px',
        maxHeight: '400px'
    };

    return (
        <>
            <Row>
                {images.map((url, index) => {
                    return (
                        <Col xs={12} md={4} key={index}>
                            <Image src={url} thumbnail onClick={() => openModal(url)} style={imgStyle} />
                        </Col>
                    );
                })}
            </Row>


            <MyModal images={images} isOpen={isOpen} closeModal={closeModal} />
        </>
    );
};

export default Gallery;