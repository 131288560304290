import React, { useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import introImage from './images/intro.jpg';
import aboutMeImg from './images/me.jpg';
import ContactForm from './ContactForm';
import MyGallery from "./MyGallery";
import Footer from "./Footer";
import emailjs from 'emailjs-com';
import myIcon from './images/gateway-arch.ico';
import cashAppIcon from './images/cashapp-badge.png';
import venmoIcon from './images/venmo-icon.png';
import MyNavBar from "./MyNavBar";

const App = () => {
    emailjs.init('nae8LhITRJKqSqrvm');
    React.useEffect(() => {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = myIcon;
        document.getElementsByTagName('head')[0].appendChild(link);
    });
  return (
      <>
          <MyNavBar/>

          <Container id="intro">
              <div className="p-4 p-md-5 mb-5 rounded text-body-emphasis bg-light">
                  <div className="row">
                      <div className="col-lg-6 px-0">
                          <h1 className="display-4 fst-italic">The Dog Den STL: Training & Boarding</h1>
                          <p className="lead my-3">Welcome to The Dog Den STL. Created from Margaret's unique handling methods developed
                              through her extensive service in local shelters. She offers force-free training and boarding for all dogs,
                              ensuring they receive the care and discipline they need. Here, every pet is loved and treated as our own.</p>
                      </div>
                      <div className="col-lg-6 d-flex justify-content-center">
                          <img src={introImage} className="img-thumbnail img-fluid" alt="Intro"
                               style={{maxWidth: "60%", height: "auto"}}/>
                      </div>
                  </div>
              </div>
          </Container>

          <Container id="services">
              <div className="row">
                  {/* Card 1 */}
                  <div className="col-lg-6 mb-3">
                      <div className="card text-center box-shadow">
                          <div className="card-header custom-success">
                              <h4 className="my-0 font-weight-normal text-light">Training Package</h4>
                          </div>
                          <div className="card-body bg-light" style={{minHeight: '280px'}}>
                              <h1 className="card-title pricing-card-title">$400 <small className="text-muted">/
                                  5 sessions</small></h1>
                              <ul className="list-unstyled mt-3 mb-4">
                                  <li className="mb-2 left-align-text"><strong>Best suited to:</strong> Older, anxious dogs or those
                                      with behavioral issues.
                                  </li>
                                  <li className="mb-2 left-align-text"><strong>Sessions:</strong> 1-hour, 5 days a week where I train
                                      your dog in your home.
                                  </li>
                                  <li className="mb-2 left-align-text"><strong>Activities:</strong> Enrichment activity & hands-on work
                                      towards your dog's behavior goals.
                                  </li>
                                  <li className="mb-2 left-align-text"><strong>Includes:</strong> Two 1-hour private lessons to discuss
                                      progress & communication.
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>

                  {/* Card 2 */}
                  <div className="col-lg-6 mb-3">
                      <div className="card text-center box-shadow">
                          <div className="card-header custom-success">
                              <h4 className="my-0 font-weight-normal text-light">Private Lessons</h4>
                          </div>
                          <div className="card-body bg-light" style={{minHeight: '280px'}}>
                              <h1 className="card-title pricing-card-title">$75 <small className="text-muted">/
                                  session</small></h1>
                              <ul className="list-unstyled mt-3 mb-4">
                                  <li className="mb-2 left-align-text"><strong>Become a Dog Trainer:</strong> Gain the skills through hands-on guidance with your dog.</li>
                                  <li className="mb-2 left-align-text"><strong>In-Home Sessions:</strong> Training at your convenience.</li>
                                  <li className="mb-2 left-align-text"><strong>Enhanced Bonding:</strong> Learn your dog's language & how to effectively communicate.</li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="row mb-4">

                  {/* Card 3 */}
                  <div className="col-lg-6 mb-3">
                      <div className="card text-center box-shadow">
                          <div className="card-header custom-success">
                              <h4 className="my-0 font-weight-normal text-light">Board & Train</h4>
                          </div>
                          <div className="card-body bg-light" style={{ minHeight: '280px' }}>
                              <h1 className="card-title pricing-card-title">$90 <small className="text-muted">/
                                  day</small></h1>
                              <ul className="list-unstyled mt-3 mb-4">
                                  <li className="mb-2 left-align-text"><strong>Best suited to:</strong> Puppies and younger dogs.</li>
                                  <li className="mb-2 left-align-text"><strong>Duration:</strong> Up to a week long stay with 4 training sessions daily.</li>
                                  <li className="mb-2 left-align-text"><strong>Care:</strong> Socialization, feeding, enrichment activities, rest.</li>
                                  <li className="mb-2 left-align-text"><strong>Includes:</strong> Two 1-hour private lessons to discuss progress & communication.</li>
                              </ul>
                          </div>
                      </div>
                  </div>

                  {/* Card 4 */}
                  <div className="col-lg-6 mb-3">
                      <div className="card text-center box-shadow">
                          <div className="card-header custom-success">
                              <h4 className="my-0 font-weight-normal text-light">Boarding</h4>
                          </div>
                          <div className="card-body bg-light" style={{ minHeight: '280px' }}>
                              <h1 className="card-title pricing-card-title">$45 <small className="text-muted">/
                                  day</small></h1>
                              <ul className="list-unstyled mt-3 mb-4">
                                  <li className="mb-2 left-align-text"><strong>Best suited to:</strong> Adult dogs, Other pets may be accommodated.</li>
                                  <li className="mb-2 left-align-text"><strong>Duration:</strong> Up to two-week stay at my home.</li>
                                  <li className="mb-2 left-align-text"><strong>Care:</strong> Socialization, outdoor play, feeding, enrichment activities, rest.</li>
                                  <li className="mb-2 left-align-text"><strong>Our Promise:</strong> Safety, security, & love like my own pet.</li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </Container>

          <Container id="contact" className={"mb-5"}>
              <ContactForm/>
          </Container>

          <Container id="about">
              <h2 className="mb-4">About Me</h2>
              <div className="row mb-5">
                  <div className="col-lg-7">
                      <p>My name is Margaret Englert, a dedicated and experienced dog training professional based
                          in St. Louis, Missouri. I carry over 8 years of rich experience in the field.</p>

                      <p>I have Specialized Dog Training Certification and have accomplished several additional certifications including Fear Free Certification Shelter Behavioral Management,
                          AAWA Shelter Management, and Maddie's University Dog Handling in the Shelter; all reflecting my diverse skill set and dedication on canine behavior and management.
                          My educational journey extends to being a well-educated and passionate dog trainer.</p>

                      <p>I take great pride in having spent the last 8 years working in different roles at the respected Animal Protective Association (APA) in St. Louis.
                          My roles varied from cleaning to training and even assumed the duty of an Enrichment Coordinator demonstrating my versatility and commitment.</p>

                      <p>About my personal life, I am a born and raised St. Louisan cherishing the vibrant music scene and the lively outdoors after a baseball match.
                          Above all, I'm an avid lover of all-things nature and have always had unbound affection for animals. </p>

                      <p>I currently have two cats who are dog socializing experts: a long hair gray tabby named Sokka, and a mini mountain lion named Zuko. My two dogs:
                          a Husky-Lab mix named Arya, and a Chihuahua-Schnauzer mix named Po, spend their days learning new things & wrestling in the yard.</p>

                      <p>I look forward to sharing my knowledge and experience to help create a happy, healthy, and mutually beneficial relationship between you and your four-legged furry friend. </p>
                  </div>
                  <div className="col-lg-5 text-center">
                      <img src={aboutMeImg} alt="Margaret Englert" className="img-fluid img-thumbnail"
                           style={{maxWidth: "70%", height: "auto"}}/>
                  </div>
              </div>
          </Container>

          <Container id="gallery" className="mb-5">
              <h2 className={"mb-4"}>Gallery</h2>
              <MyGallery/>
          </Container>

          <Footer/>
      </>
  )
}

export default App;
