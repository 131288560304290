import React, {useState} from 'react';
import emailjs from 'emailjs-com';
import {Form, Button, Row, Col, Dropdown} from 'react-bootstrap';
import Swal from 'sweetalert2'

function ContactForm() {
    const [selectedOption, setSelectedOption] = useState("Select a service");

    const handleSelect = (selectedKey) => {
        setSelectedOption(selectedKey);
        document.getElementById('service').value = selectedKey;
    }

    const sendEmail = (e) => {
        e.preventDefault()

        if(selectedOption === "Select a service"){
            alert("Please select a service");
            return;  // Stop the function here
        }

        emailjs.sendForm('service_glevpsf', 'template_xw1z68q', e.target, 'nae8LhITRJKqSqrvm')
            .then((result) => {
                Swal.fire({
                    title: 'Success!',
                    text: 'Your form has been submitted and we will reach out to you!',
                    icon: 'success',
                    confirmButtonText: 'OK'
                })
            }, (error) => {
                Swal.fire({
                    title: 'Error!',
                    text: 'An error has occurred, if this continues please reach out to margs810@gmail.com to request a service.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                })
            });
    }

    return (
        <div style={{padding: '20px', width: '95%', margin: 'auto'}} className={"bg-light"}>
            <h2 style={{marginBottom: '20px', textAlign: 'center'}}>Contact Us</h2>
            <Form onSubmit={sendEmail} style={{maxWidth: '95%', margin: 'auto'}}>
                <Row className="mb-4">
                    <Col>
                        <Form.Group controlId="firstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" placeholder="First Name" name="first_name" required/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="lastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" placeholder="Last Name" name="last_name" required/>
                        </Form.Group>
                    </Col>
                </Row>


                <Form.Group controlId="phoneNumber" className="mb-4">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control type="tel" placeholder="Phone" name="phone_number" required/>
                </Form.Group>

                <Form.Group controlId="email" className="mb-4">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Email" name="email" required/>
                </Form.Group>

                <Form.Group className="mb-4">
                    <Form.Label>Communication Preference:</Form.Label>
                    <div>
                        <Form.Check inline label="Text" value="Text" type="radio" id="text"
                                    name="communicationPreference" required/>
                        <Form.Check inline label="Email" value="Email" type="radio" id="email"
                                    name="communicationPreference" required/>
                    </div>
                </Form.Group>

                <Form.Group controlId="dogName" className="mb-4">
                    <Form.Label>Dog's Name</Form.Label>
                    <Form.Control type="text" placeholder="Dog's Name" name="dog_name" required/>
                </Form.Group>

                <Dropdown onSelect={handleSelect} className="mb-4">
                    <Dropdown.Toggle variant="outline-dark" id="dropdown-basic" style={{ width: '50vw', maxWidth: '300px' }}>
                        {selectedOption}
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ width: '50vw', maxWidth: '300px' }}>
                        <Dropdown.Item eventKey="Private Lessons">Private Lessons</Dropdown.Item>
                        <Dropdown.Item eventKey="In-Home Training">In-Home Training</Dropdown.Item>
                        <Dropdown.Item eventKey="Board & Train">Board & Train</Dropdown.Item>
                        <Dropdown.Item eventKey="Boarding">Boarding</Dropdown.Item>
                        <Dropdown.Item eventKey="Other">Other</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Form.Group controlId="comments" className="mb-4">
                    <Form.Label>Comments</Form.Label>
                    <Form.Control as="textarea" rows={3} name="comments"/>
                </Form.Group>

                <input type="hidden" name="service" id="service" value={selectedOption}/>

                <Button variant="primary" type="submit" className="mb-5">
                    Submit
                </Button>

            </Form>
        </div>
    );
}

export default ContactForm;