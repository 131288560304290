import React, { useState, useRef, useEffect } from "react";
import { Navbar, Nav, Container } from 'react-bootstrap';
import venmoIcon from "./images/venmo-icon.png";
import cashAppIcon from "./images/cashapp-badge.png";
import {FaFacebook, FaInstagram, FaLinkedin} from "react-icons/fa";

const MyNavBar = () => {

    const node = useRef();
    const [expanded, setExpanded] = useState(false);

    const handleClickOutside = e => {
        if (node.current.contains(e.target)) {
            return;
        }
        setExpanded(false);
    };

    const closeOnScroll = () => {
        if (expanded) {
            setExpanded(false);
        }
    };

    useEffect(() => {
        if (expanded) {
            document.addEventListener("mousedown", handleClickOutside);
            window.addEventListener('scroll', closeOnScroll);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
            window.removeEventListener('scroll', closeOnScroll);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            window.removeEventListener('scroll', closeOnScroll);
        };
    }, [expanded]);

    return (
        <Navbar ref={node} expanded={expanded} onToggle={() => setExpanded(!expanded)} bg="success" variant="dark" expand="lg" fixed="top" className="custom-navbar">
            <Container>
                <Navbar.Brand onClick={() => setExpanded(false)} href="#intro">The Dog Den STL</Navbar.Brand>
                <Navbar.Toggle onClick={() => setExpanded(!expanded)} aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link onClick={() => setExpanded(false)} href="#services">Services</Nav.Link>
                        <Nav.Link onClick={() => setExpanded(false)} href="#about">About Me</Nav.Link>
                        <Nav.Link onClick={() => setExpanded(false)} href="#contact">Contact Us</Nav.Link>
                        <Nav.Link onClick={() => setExpanded(false)} href="#gallery">Gallery</Nav.Link>
                        <Nav.Link onClick={() => setExpanded(false)} href="#socials">Socials</Nav.Link>
                    </Nav>
                    <Nav className="ml-auto d-flex flex-row">
                        <Nav.Link onClick={() => setExpanded(false)}  href="https://www.linkedin.com/in/margaret-englert-1a5b152b0/" target="_blank" rel="noopener noreferrer" className="mr-2">
                            <FaLinkedin onClick={() => setExpanded(false)} size={30} color={"white"} />
                        </Nav.Link>

                        <Nav.Link onClick={() => setExpanded(false)}  href="https://www.facebook.com/profile.php?id=100090120180151" target="_blank" rel="noopener noreferrer" className="mr-2">
                            <FaFacebook size={30} onClick={() => setExpanded(false)} color={"white"} />
                        </Nav.Link>

                        <Nav.Link onClick={() => setExpanded(false)}  href="https://www.instagram.com/diggitydog.training/" target="_blank" rel="noopener noreferrer" className="mr-2">
                            <FaInstagram onClick={() => setExpanded(false)} size={30} color={"white"} />
                        </Nav.Link>
                        <Nav.Link onClick={() => setExpanded(false)} href="https://venmo.com/margret-englert" target="_blank" rel="noopener noreferrer" className="mr-2">
                            <img onClick={() => setExpanded(false)} src={venmoIcon} alt="Venmo" width="30" height="30"/>
                        </Nav.Link>
                        <Nav.Link onClick={() => setExpanded(false)} href="https://cash.app/$margretenglert" target="_blank" rel="noopener noreferrer">
                            <img onClick={() => setExpanded(false)} src={cashAppIcon} alt="Cash App" width="30" height="30"/>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
export default MyNavBar