import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { FaLinkedin, FaFacebook, FaInstagram } from 'react-icons/fa';

const Footer = () => {
    return (
        <Navbar bg="success" variant="success" className="custom-navbar"  id="socials">
            <Container className="d-flex justify-content-center align-items-center">
               <Nav>
                    <Nav.Link href="https://www.linkedin.com/in/margaret-englert-1a5b152b0/" target="_blank">
                        <FaLinkedin size={30} color={"white"} />
                    </Nav.Link>

                    <Nav.Link href="https://www.facebook.com/profile.php?id=100090120180151" target="_blank">
                        <FaFacebook size={30} color={"white"} />
                    </Nav.Link>

                    <Nav.Link href="https://www.instagram.com/diggitydog.training/" target="_blank">
                        <FaInstagram size={30} color={"white"} />
                    </Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    );
};

export default Footer;